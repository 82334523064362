import React, { useState, useEffect } from 'react'
import Lay from '../../components/Layout/Lay'
import AdminMenu from '../../components/Layout/AdminMenu'
import toast from 'react-hot-toast'
import axios from 'axios'
import CategoryForm from '../../components/Form/CategoryForm'
import { Modal } from 'antd'

const CreateCategory = () => {
  const [categories, setCategories] = useState([])
  const [name, setName] = useState("")
  const [visible,setVisible]=useState(false)
  const [selected,setSelected]=useState(null)
  const [updatedName,setUpdatedName]=useState("")

  //handle submit form
  const handleSubmit =async (e) => {
    e.preventDefault()
    try {
      const { data } = await axios.post('/api/v1/category/create-category', {
        name,
      })
      if (data?.success) {
        toast.success(`${name} is created`)
        getAllCategory()
      } else {
        toast.error(data.message)
      }
    }
    catch (error) {
      console.log(error)
      toast.error('Something went wrong in input')
    }
  }

  //get all category
  const getAllCategory = async () => {
    try {
      const { data } = await axios.get('/api/v1/category/get-category')
      if (data.success) {
        setCategories(data.category)
      }
    } catch (error) {
      console.log(error)
      toast.error("Something went wrong in getting category")
    }
  }

  useEffect(() => {
    getAllCategory()
  }, [])

  //Update Category
 const handleUpdate =async(e)=>{
  e.preventDefault()
  try{
const {data}= await axios.put(`/api/v1/category/update-category/${selected._id}`,{name:updatedName})
if(data.success){
  toast.success(`${updatedName} is updated`)
  setSelected(null)
  setUpdatedName("")
  setVisible(false)
  getAllCategory()
}else{
  toast.error(data.message)
}

}
  catch(error){
    toast.error('Something went wrong')
  }
 }

 ///delete category
  const handleDelete = async (pId) => {
    try {
      const { data } = await axios.delete(
        `/api/v1/category/delete-category/${pId}`
      );
      if (data.success) {
        toast.success(`category is deleted`);

        getAllCategory();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Somtihing went wrong");
    }
  };

  return (
    <Lay title={"Create-Category - Admin"}>
      <div style={{ backgroundColor: "#bad5ff", height: "100vh" }} className='container-fluid p-3'>
        <div className='row'>
          <div className='col-md-3'>
            <AdminMenu />
          </div>
          <div className='col-md-9'>
            <h1>Manage Category</h1>
            <div className='p-3 w-50'>
              <CategoryForm handleSubmit={handleSubmit} value={name} setValue={setName}/>
            </div>
            <div className='w-75'>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>

                  {categories?.map((c) => (
                    <>
                      <tr>
                        <td key={c._id}>{c.name}</td>
                        <td>
                          <button className='btn btn-outline-dark ms-2' 
                          onClick={()=>{setVisible(true);setUpdatedName(c.name);
                          setSelected(c)}}
                          >Edit</button>
                          <button className='btn btn-danger ms-2'
                          onClick={()=>{handleDelete(c._id)}}
                          >Delete</button>
                          </td>
                        
                      </tr>
                    </>
                  ))}

                </tbody>
              </table>

            </div>
            <Modal onCancel={()=>setVisible(false)}
            footer={null} visible={visible}
            >
              <CategoryForm handleSubmit={handleUpdate} value={updatedName} setValue={setUpdatedName}/>
            </Modal>
          </div>
        </div>
      </div>
    </Lay>
  )
}

export default CreateCategory