export  const Prices=[
    {
        _id:0,
        name:"Rs.0 to Rs.499",
        array:[0,499]
    },
    {
        _id:1,
        name:"Rs.500 to Rs.999",
        array:[500,999]
    },
    {
        _id:2,
        name:"Rs.1000 to Rs.1499",
        array:[1000,1499]
    },
    {
        _id:3,
        name:"Rs.1500 to Rs.1999",
        array:[1500,1999]
    },
    {
        _id:4,
        name:"Rs.2000 to Rs.2499",
        array:[2000,2499]
    },
    {
        _id:5,
        name:"Rs.2500 to more",
        array:[2500,99999]
    },
    {
        _id:6,
        name:"All Products",
        array:[0,99999]
    },
]