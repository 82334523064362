import { Routes, Route } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage';
import About from './pages/About';
import Contact from './pages/Contact';
import Policy from './pages/Policy';
import Pagenotfound from './pages/Pagenotfound';
import Register from './pages/Auth/Register';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './pages/Auth/Login';
import Dashboard from './pages/user/Dashboard';
import PrivateRoute from './components/Routes/Private';
import ForgotPassword from './pages/Auth/ForgotPassword';
import AdminRoute from './components/Routes/AdminRoute';
import AdminDashboard from './pages/Admin/AdminDashboard';
import CreateProduct from './pages/Admin/CreateProduct';
import Users from './pages/Admin/Users';
import CreateCategory from './pages/Admin/CreateCategory';
import Profile from './pages/user/Profile';
import Orders from './pages/user/Orders';
import Products from './pages/Admin/Products';
import UpdateProduct from './pages/Admin/UpdateProduct';
import Search from './pages/Search';
import ProductDetails from './pages/ProductDetails';
import BlogDetails from './pages/BlogDetails';
import Categories from './pages/Categories';
import CategoryProduct from './pages/CategoryProduct';
import CartPage from './pages/CartPage';
import AdminOrders from './pages/Admin/AdminOrders';
import CreateBanner from './pages/Admin/CreateBanner';
import BannerPage from './pages/BannerPage';
import FavouritePage from './pages/FavouritePage';
import Shop from './pages/Shop';
import Blog from './pages/Blog';
import Terms from './pages/Terms';
import Refund from './pages/Refund';
import ShipmentPolicy from './pages/ShipmentPolicy';
import WhoWeAre from './pages/WhoWeAre';
import CreateBlog from './pages/Admin/CreateBlog';
import Blogs from './pages/Admin/Blogs';
import UpdateBlog from './pages/Admin/UpdateBlog';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/product/:slug" element={<ProductDetails />} />
        <Route path="/blog/:slug" element={<BlogDetails />} />
        <Route path="/banner/bannerpage" element={<BannerPage />} />
        <Route path="/categories" element={<Categories/>} />
        <Route path="/category/:slug" element={<CategoryProduct/>} />
        <Route path="/cart" element={<CartPage/>} />
        <Route path="/favourite" element={<FavouritePage/>} />
        <Route path="/search" element={<Search />} />
        <Route path="/register" element={<Register />} />
        <Route path='/dashboard' element={<PrivateRoute />}>
          <Route path="user" element={<Dashboard />} />
          <Route path="user/profile" element={<Profile />} />
          <Route path="user/orders" element={<Orders />} />
        </Route>
        <Route path='/dashboard' element={<AdminRoute />}>
          <Route path='admin' element={<AdminDashboard />} />
          <Route path='admin/create-category' element={<CreateCategory />} />
          <Route path='admin/create-product' element={<CreateProduct />} />
          <Route path='admin/create-banner' element={<CreateBanner />} />
          <Route path='admin/product/:slug' element={<UpdateProduct />} />
          <Route path='admin/products' element={<Products/>} />
          <Route path='admin/blogs' element={<Blogs/>} />
          <Route path='admin/blog/:slug' element={<UpdateBlog />} />
          <Route path='admin/users' element={<Users />} />
          <Route path='admin/create-blog' element={<CreateBlog />} />
          <Route path='admin/orders' element={<AdminOrders />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/about" element={<About />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/blog" element={<Blog/>} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/shipmentpolicy" element={<ShipmentPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/whoweare" element={<WhoWeAre />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Pagenotfound />} />
      </Routes>
    </>
  );
}

export default App;
